import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { animated } from 'react-spring';

import { useMutation, useQuery } from '@apollo/client';
import { REGISTER_INTERESTED_PROPERTY_QUERY } from '../../../../client/__graphql__/queries';

import { useForm } from '../../../utils/hooks/useForm';
import { currency } from '../../../utils/format/currency';

import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';
import { BackgroundLoad } from '../../layout/BackgroundLoad';

import { Input } from '../../ui/form/Input';
import { Textarea } from '../../ui/form/Textarea';
import { FormError } from '../../ui/form/FormError';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Form, FormRow } from '../../ui/form/Form';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { BrokerListItem } from '../../ui/broker/ListItem';
import { Checkbox } from '../../ui/form/Checkbox';

import { Thanks } from '../../layout/Thanks';

import { useFormTransition } from '../../../utils/hooks/useFormTransition';

import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { INTERESTED_MUTATION } from '../../../../client/__graphql__/mutations';
import { createGTMEvent } from '../../../utils/tracking/gtm';
import NoMatch from '../404Old';
import { useSiteContext } from '../../../utils/hooks/useSiteContext';
import { useReferrerAndUrl } from '../../../utils/hooks/useReferrerAndUrl';

import {
  GetConsentsByIdsQuery,
  GetConsentsByIdsQueryVariables,
  LeadInput,
  LeadResponse,
  LeadType,
  PageType
} from '../../../__types__/generated';
import { SAMTYKKER_MUTATION } from '../../../../client/__graphql__/mutations/samtykke';
import { GET_CONSENTS } from '../../../../client/__graphql__/queries/samtykke';
import { useDigtectiveContext } from '../../../__lib__/digtective/context/hooks/useDigtectiveContext';

const RegisterInterest: React.FC = () => {
  const { routeConfig } = useSiteContext();
  const { referrer, url } = useReferrerAndUrl();

  const params = useParams<{ sguid: string; page: string }>();
  const [priceOver8Mill, setPriceOver8Mill] = React.useState(false);
  const [posted, setPosted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [samtykkeIds, setSamtykkeIds] = React.useState<number[]>([]);
  const { submitWithDigger } = useDigtectiveContext();
  const [registrerSamtykker] = useMutation(SAMTYKKER_MUTATION);

  const { data, loading: propertyLoading } = useQuery(
    REGISTER_INTERESTED_PROPERTY_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          sguid: params.sguid,
          includeAccepted: true,
          sold: false
        }
      },
      onCompleted: ({ property }) => {
        // Check if price is over 5 mill. If so, show Nordea button
        if (property?.price?.info?.price > 5000000) {
          setPriceOver8Mill(true);
        }
      }
    }
  );

  const { data: consents } = useQuery<
    GetConsentsByIdsQuery,
    GetConsentsByIdsQueryVariables
  >(GET_CONSENTS, {
    variables: { ids: [7] },
    fetchPolicy: 'cache-first'
  });

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: !propertyLoading && data
  });

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const [lead, { error }] = useMutation<
    { lead: LeadResponse },
    { input: LeadInput }
  >(INTERESTED_MUTATION, {
    onError: ({ graphQLErrors, networkError }) => {
      setLoading(false);
      console.log(graphQLErrors, networkError);
    },
    onCompleted: ({ lead }) => {
      setLoading(false);

      if (lead.interested && lead.interested.success) {
        window.scroll(0, 0);
        setPosted(true);
      }
    }
  });

  const { fields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'firstName',
        placeholder: 'Ola',
        value: '',
        label: 'Fornavn *',
        required: true
      },
      {
        type: 'text',
        name: 'lastName',
        placeholder: 'Nordmann',
        value: '',
        label: 'Etternavn *',
        required: true
      },
      {
        type: 'text',
        name: 'address',
        placeholder: 'Adresseveien 23',
        value: '',
        label: 'Adresse'
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: '1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: '00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'textarea',
        name: 'comment',
        placeholder: 'Kommentar',
        value: '',
        label: 'Kommentar',
        labelAfter: ''
      },
      ...(data?.property.hovedoppdrag !== null
        ? [
            {
              type: 'checkbox',
              name: 'delmedutbygger',
              value: 1,
              checked: false,
              label: 'og dele min informasjon med utbygger'
            }
          ]
        : []),
      ...(data?.property.hovedoppdrag === null
        ? [
            {
              type: 'checkbox',
              name: 'tipsomliknende',
              value: 1,
              checked: false,
              label:
                'om lignende eiendommer som kommer for salg og annen eiendomsrelatert informasjon'
            },
            {
              type: 'checkbox',
              name: 'kjop',
              value: 1,
              checked: false,
              label: 'I forbindelse med hjelp til å finne og kjøpe eiendommer'
            }
          ]
        : []),
      {
        type: 'checkbox',
        name: 'skalselgebolig',
        value: 1,
        checked: false,
        label:
          'i forbindelse med salg og verdivurdering/e-takst av min nåværende eiendom'
      },
      ...(consents?.getAvailableSamtykkerByIds?.length
        ? consents?.getAvailableSamtykkerByIds
            ?.map((data, index) => {
              if (!data?.id) return null;
              const { id: value, samtykketekst: label = '' } = data;
              let name = `consent${index}`;
              switch (value) {
                case 5:
                  name = 'marketing';
                  break;
                case 7:
                  name = 'marketingPromo';
                  break;
              }
              return {
                type: 'checkbox',
                name,
                checked: false,
                value,
                label,
                arrayName: 'consentIds'
              };
            })
            ?.reduce<any[]>((initValue = [], currentValue) => {
              if (!currentValue?.value) return initValue;
              initValue.push(currentValue);
              return initValue;
            }, [])
        : [])
    ],
    submit: (fields) => {
      setLoading(true);

      const createInputObj = (args?: {
        diggerId: string;
      }): { input: LeadInput } => {
        return {
          input: {
            leadType: LeadType.Interessent,
            firstName: fields.firstName,
            lastName: fields.lastName,
            address: fields.address,
            zip: fields.zip,
            email: fields.email,
            phone: fields.phone,
            comment: fields.comment,
            wmId: data.property.wmId,
            identifier: data.property.id,
            consents: {
              skalselgebolig: fields?.skalselgebolig || false,
              tipsomliknende: fields?.tipsomliknende || false,
              kjop: fields?.kjop || false,
              finansieringmarkedsforing: fields?.marketing || false,
              marketingPromo: fields?.marketingPromo || false
            },
            diggerId: args?.diggerId || '',
            pageType: PageType.Objekt,
            referer: referrer,
            url: url
          }
        };
      };

      /* Fix for Ko & CO (DIGGER) */
      if (fields.skalselgebolig) {
        submitWithDigger(
          {
            zip: fields.zip
          },
          ({ diggerId }) => {
            lead({
              variables: createInputObj({
                diggerId: diggerId || ''
              })
            });
          }
        );
      } else {
        if (fields?.consentIds?.length) {
          registrerSamtykker({
            variables: {
              input: {
                navn: `${fields.firstName} ${fields.lastName}`,
                epost: `${fields.email}`,
                telefon: `${fields.phone}`,
                tjeneste: 1,
                samtykker: fields?.consentIds
              }
            }
          });
        }
        lead({
          variables: createInputObj()
        });
      }

      createGTMEvent({
        event: 'pmFormSubmission',
        gaEvent: 'RT_INTERESSENT',
        gaCategory: 'RT_INTERESSENT',
        gaAction: 'RT_SEND_INTERESSENT'
      });
      if (fields.skalselgebolig) {
        createGTMEvent({
          event: 'pmFormSubmission',
          gaEvent: 'RT_VALUATION',
          gaCategory: 'RT_VALUATION',
          gaAction: 'RT_SEND_INTERESSENT'
        });
      }
    }
  });

  if (propertyLoading) {
    return null;
  }

  if (!data || !data.property) {
    return <NoMatch />;
  }

  const ButtonGroupWrapper = styled.div`
    .cta-receipt-button {
      justify-content: center;
      padding-top: 1em;
    }
  `;
  return (
    <>
      <Container
        style={{ ...pageFadeIn, minHeight: '100vh' }}
        className="padding-top"
      >
        <FormWrapper style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            <Heading tag="h1" center={true}>
              {params.page === 'registrerinteressent' ||
              params.page === 'registrerinteressentobjekt'
                ? 'Bestill salgsoppgave'
                : params.page === 'meldinteresse'
                ? 'Meld interesse'
                : params.page === 'onsker-budvarsel'
                ? ' Ønsker budvarsel'
                : null}
            </Heading>
            {data?.property?.address ? (
              <Heading
                tag="h3"
                center={true}
                color="white"
                className="notfollowingstandards"
              >
                {data.property.address}
                {data?.property?.matrikkel?.info?.unit && (
                  <div>{data?.property?.matrikkel?.info?.unit}</div>
                )}
              </Heading>
            ) : null}
            {data?.property?.price?.info?.price ? (
              data?.property?.hovedoppdrag === -1 &&
              data?.property?.price?.info?.priceTo ? (
                <Heading
                  tag="h3"
                  center={true}
                  color="white"
                  className="notfollowingstandards"
                >
                  Prisantydning{' '}
                  {currency({ number: data.property.price.info.price })},- til{' '}
                  {currency({ number: data.property.price.info.priceTo })},-
                </Heading>
              ) : (
                <Heading
                  tag="h3"
                  center={true}
                  color="white"
                  className="notfollowingstandards"
                >
                  Prisantydning{' '}
                  {currency({ number: data.property.price.info.price })},-
                </Heading>
              )
            ) : null}
            <Paragraph center={true}>
              Jeg ønsker å få tilsendt salgsoppgave og anmoder megler om å holde
              meg oppdatert i forbindelse med salget av denne eiendommen, på
              e-post og /eller telefon i form av sms eller oppringing. Dersom du
              ikke ønsker å bli kontaktet finner du komplett salgsoppgave for
              eiendommen tilgjengelig{' '}
              <a href={`https://privatmegleren.no/${data.property.id}`}>her</a>
            </Paragraph>
          </Section>
          <Form onSubmit={handleSubmit} noValidate>
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}
            {fields
              .filter(
                (item: any) =>
                  item.type === 'text' ||
                  item.type === 'tel' ||
                  item.type === 'email'
              )
              .map((item: any, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Input
                      type={item.type}
                      name={item.name}
                      label={item.label}
                      labelAfter={item.labelAfter}
                      placeholder={item.placeholder}
                      value={item.value}
                      onChange={handleChange}
                      error={item.error}
                    />
                  </FormRow>
                );
              })}
            {fields
              .filter((item: any) => item.type === 'textarea')
              .map((item: any, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Textarea
                      name={item.name}
                      label={item.label}
                      labelAfter={item.labelAfter}
                      placeholder={item.placeholder}
                      value={item.value}
                      onChange={handleChange}
                      error={item.error}
                    />
                  </FormRow>
                );
              })}
            <Samtykker>
              <SamtykkeHeader>
                Jeg anmoder PrivatMegleren om å kontakte meg på e-post og /eller
                telefon i form av sms eller oppringing.
              </SamtykkeHeader>
              {fields
                .filter((item: any) => item.type === 'checkbox')
                .map((item: any, index) => {
                  return (
                    <FormRow key={`field_${index}`}>
                      <Checkbox
                        {...item}
                        onChange={handleChange}
                        error={item.error}
                        labelPos="right"
                      />
                    </FormRow>
                  );
                })}
            </Samtykker>
            <Paragraph center={true} className="form-consent-text">
              * Ved bestilling av salgsoppgave aksepterer du at eiendomsmegler
              kan kontakte deg med informasjon om eiendommen, som for eksempel
              endringer i salgsoppgaven.{' '}
              <a href={routeConfig?.personvern.url}>Personvernpolicy</a>
            </Paragraph>
            <ButtonGroup position="center" className="space-top-bottom">
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Send
              </Button>
            </ButtonGroup>
          </Form>
          <Section className="form-section">
            <Heading tag="h3" center={true} color="white">
              Har du spørsmål?
            </Heading>
            <BrokerList>
              {data?.property?.brokers?.list.map((item: any, index: number) => (
                <BrokerListItem
                  key={`broker_${index}`}
                  name={item?.name}
                  title={item?.title}
                  office={item?.office?.info?.name}
                  image={item?.image?.full}
                  path={`https://privatmegleren.no/megler/${item?.path}`}
                />
              ))}
            </BrokerList>
          </Section>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h2" center={true}>
              Takk for din henvendelse
            </Heading>
            <Paragraph center={true}>Du hører fra oss om kort tid.</Paragraph>
            <div style={{ marginTop: '4rem', marginBottom: '4rem' }} />
            {priceOver8Mill ? (
              <>
                <Heading tag="h3" center={true}>
                  Trenger du finansiering?
                </Heading>
                <Paragraph center={true}>
                  Les mer om hva Nordea kan tilby deg{' '}
                </Paragraph>
                <ButtonGroupWrapper>
                  <ButtonGroup className="cta-receipt-button">
                    <Button
                      type="primary"
                      colorTheme="gold"
                      as="a"
                      href="https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/privatmegleren-og-nordea-kontaktskjema.html?cid=partner-tjkjd4jhpb"
                      target="_blank"
                      center={true}
                    >
                      {' '}
                      Klikk her
                    </Button>
                  </ButtonGroup>
                </ButtonGroupWrapper>
              </>
            ) : (
              <>
                <Heading tag="h3" center={true}>
                  Trenger du finansiering?
                </Heading>
                <Paragraph center={true}>
                  Les mer om hva Nordea kan tilby deg{' '}
                  <ButtonGroupWrapper>
                    <ButtonGroup className="cta-receipt-button">
                      <Button
                        type="primary"
                        colorTheme="gold"
                        as="a"
                        href="https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/jeg-onsker-a-kjope-en-bolig.html?cid=partner-q78atrt7fh"
                        target="_blank"
                        center={true}
                      >
                        {' '}
                        Klikk her
                      </Button>
                    </ButtonGroup>
                  </ButtonGroupWrapper>
                </Paragraph>
              </>
            )}

            <div style={{ marginTop: '4rem', marginBottom: '4rem' }} />
            <Paragraph center={true}>
              Under kan du få vite mer om PrivatMegleren. Vi tilbyr blant annet
              bistand til kjøp for deg som ser etter ny eiendom.
            </Paragraph>

            <iframe
              style={{
                width: '100%',
                height: '300px',
                justifyContent: 'center',
                marginTop: '1em'
              }}
              src="https://player.vimeo.com/video/515185604"
              frameBorder="0"
              allow="fullscreen"
              allowFullScreen
            ></iframe>
          </Section>
        </Thanks>
      </Container>
      <BackgroundLoad
        showOnMobile={true}
        opacity={0.35}
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </>
  );
};

export default RegisterInterest;

const FormWrapper = styled(animated.div)`
  .notfollowingstandards {
    font-size: 1.5em;
  }
`;

const BrokerList = styled.div`
  display: flex;
  flex-flow: column;
`;

const Samtykker = styled.div`
  margin: 1em 0 0;
  padding: 1em;
  clear: both;
  overflow: auto;
  background-color: rgb(24, 24, 25);
`;
const SamtykkeHeader = styled.div`
  color: #fff;
  margin: 0 0 1em;
  font-size: 16px;
`;
